import './_inject-styles.js';
import './_fire-init-event.js';

import { flushSync } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import store from 'Core/store.ts';
import makeTemplateApplicator from 'Components/components.jsx';

import { Comparer, SearchPage, ContextDialog } from 'Components';
import { runWhenReady, setupPage } from './_common.js';
import widgetsConfig from 'Models/uiConfig/widgetsConfig.ts';
import {
  comparerEnabled,
  searchPageEnabled,
  contextDialogEnabled,
} from '!!extract-features!Stores/the-aeromotive-group/config.js';

const syncRenderedComponents = ['FacetTiles', 'VehicleTabs', 'DialogButton', 'Garage'];

function renderAllComponents() {
  const templateContext = {};
  const widgetsWithContext = {
    Comparer: [],
    SearchPage: [],
    ContextDialog: [],
  };
  widgetsConfig.forEach((widget) => {
    const { element, name, template, type, visible, isRoot } = widget;

    if (!visible) {
      if (!isRoot) {
        templateContext[name] = () => null;
      }
      return;
    }

    if (widgetsWithContext[type]) {
      widgetsWithContext[type].push(widget);
      return;
    }

    const componentFromTemplate = makeTemplateApplicator(widget);

    if (componentFromTemplate) {
      if (isRoot) {
        const component = <Provider store={store}>{componentFromTemplate(template, {})}</Provider>;
        if (syncRenderedComponents.includes(type)) {
          flushSync(() => {
            createRoot(element).render(component);
          });
        } else {
          createRoot(element).render(component);
        }
      } else {
        templateContext[name] = componentFromTemplate;
      }
    } else {
      console.warn(`Widget type ${type} not found`, widget);
    }
  });

  if (comparerEnabled) {
    widgetsWithContext.Comparer.forEach(({ element, template }) => {
      if (element) {
        createRoot(element).render(
          <Provider store={store}>
            <Comparer template={template} />
          </Provider>,
        );
      }
    });
  }

  if (searchPageEnabled) {
    widgetsWithContext.SearchPage.forEach((widget) => {
      const { element, template } = widget;
      if (element) {
        const props = { template, context: templateContext };
        createRoot(element).render(
          <Provider store={store}>
            <SearchPage {...props} />
          </Provider>,
        );
      }
    });
  }

  if (contextDialogEnabled) {
    widgetsWithContext.ContextDialog.forEach((widget) => {
      const { element, template, name, closeOnClickOutside } = widget;
      if (element) {
        const props = { template, context: templateContext, name, closeOnClickOutside };
        createRoot(element).render(
          <Provider store={store}>
            <ContextDialog {...props} />
          </Provider>,
        );
      }
    });
  }
}

setupPage();
runWhenReady(renderAllComponents);
