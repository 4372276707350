
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function homeVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3594'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3796'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', 'Select ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7871'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7874'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ 'Select ' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2883'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', 'Select ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__home' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '59'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('p', {}, 'shop by vehicle'), _createElement('h5', {}, 'What are you working on?')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ', window.document.body.classList.contains('template-product') ? 'Verify fitment' : 'GO', '\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, [_createElement('svg', {
            'width': '24',
            'height': '24',
            'viewBox': '0 0 24 24',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '68950'
        }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
            'id': 'mask0_1239_10799',
            'style': { maskType: 'alpha' },
            'maskUnits': 'userSpaceOnUse',
            'x': '0',
            'y': '0',
            'width': '24',
            'height': '24'
        }, _createElement('rect', {
            'id': 'Bounding box',
            'width': '24',
            'height': '24',
            'fill': '#D9D9D9'
        })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
            'id': 'restart_alt_2',
            'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
            'fill': 'white'
        }))))], _createElement('span', {}, 'Reset'))))) : null);
}
        export const componentNames = ["cm:filterInput"]