
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function RequestPumpDropdownPanelRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3594'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3796'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', 'Select ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7871'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7874'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ 'Select ' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2883'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', 'Select ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatInputs2(inputs, inputsIndex) {
        return [inputs(function () {
                return _createElement('div', {}, [this.facetInput(function () {
                        return _createElement('div', { 'className': 'cm_facetInput' });
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })]);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_req-panel_pump-dropdown-container' }, _createElement('button', {
        'className': 'cm_toggle-dropdown cm_req-panel_pump-dropdown_toggle button button--secondary',
        'onClick': window.Convermax.handlePumpFinderDropdownOpen
    }, [_createElement('svg', {
            'width': '24',
            'height': '24',
            'viewBox': '0 0 24 24',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '2100'
        }, _createElement('g', { 'id': 'water_pump' }, _createElement('mask', {
            'id': 'mask0_1290_13795',
            'style': { maskType: 'alpha' },
            'maskUnits': 'userSpaceOnUse',
            'x': '0',
            'y': '0',
            'width': '24',
            'height': '24'
        }, _createElement('rect', {
            'id': 'Bounding box',
            'width': '24',
            'height': '24',
            'fill': '#D9D9D9'
        })), _createElement('g', { 'mask': 'url(#mask0_1290_13795)' }, _createElement('path', {
            'id': 'water_pump_2',
            'd': 'M3.24998 17H7.92692C7.54744 16.7103 7.20161 16.3763 6.88942 15.9981C6.57724 15.6199 6.31666 15.2038 6.10767 14.75H3.24998V17ZM11.9999 17C13.3833 17 14.5624 16.5125 15.5374 15.5375C16.5124 14.5625 16.9999 13.3833 16.9999 12C16.9999 10.6167 16.5124 9.43751 15.5374 8.46251C14.5624 7.48751 13.3833 7.00001 11.9999 7.00001C10.6166 7.00001 9.43745 7.48751 8.46245 8.46251C7.48745 9.43751 6.99995 10.6167 6.99995 12C6.99995 13.3833 7.48745 14.5625 8.46245 15.5375C9.43745 16.5125 10.6166 17 11.9999 17ZM17.8922 9.25003H20.7499V7.00001H16.073C16.4525 7.28976 16.7983 7.62373 17.1105 8.00193C17.4227 8.38015 17.6832 8.79618 17.8922 9.25003ZM1.75 19.3846V12.3654H3.24998V13.25H5.6423C5.58973 13.0449 5.55287 12.8407 5.53172 12.6375C5.51056 12.4343 5.49997 12.2218 5.49997 12C5.49997 10.1893 6.13063 8.65331 7.39195 7.39201C8.65325 6.13069 10.1892 5.50003 11.9999 5.50003H20.7499V4.61543H22.2499V11.6346H20.7499V10.75H18.3576C18.4102 10.9551 18.447 11.1593 18.4682 11.3625C18.4893 11.5657 18.4999 11.7782 18.4999 12C18.4999 13.8107 17.8693 15.3467 16.6079 16.608C15.3466 17.8693 13.8106 18.5 11.9999 18.5H3.24998V19.3846H1.75ZM11.9999 14.6058C11.514 14.6058 11.1009 14.4356 10.7605 14.0952C10.4202 13.7548 10.25 13.3417 10.25 12.8558C10.25 12.5301 10.3195 12.2038 10.4586 11.8769C10.5977 11.55 10.857 11.0942 11.2365 10.5096L11.9903 9.39426L12.7538 10.5096C13.1436 11.1068 13.407 11.5654 13.5442 11.8853C13.6813 12.2052 13.7499 12.5287 13.7499 12.8558C13.7499 13.3417 13.5797 13.7548 13.2393 14.0952C12.899 14.4356 12.4858 14.6058 11.9999 14.6058Z',
            'fill': '#16181B'
        }))))], _createElement('span', {}, 'PUMP FINDER')), _createElement('div', { 'className': 'cm_req-panel_pump-dropdown cm_req-panel_pump cm_hide' }, _createElement('div', { 'className': 'cm_req-panel_pump-dropdown_header' }, _createElement('span', {}, 'PUMP FINDER'), _createElement('div', {
        'className': 'cm_req-panel_pump-dropdown_header_cross',
        'onClick': window.Convermax.handlePumpFinderDropdownOpen
    }, _createElement('svg', {
        'width': '16',
        'height': '16',
        'viewBox': '0 0 16 16',
        'fill': 'none',
        'xmlns': 'http://www.w3.org/2000/svg'
    }, _createElement('g', { 'id': 'x' }, _createElement('path', {
        'id': 'Vector',
        'd': 'M13 3L3 13',
        'stroke': 'black',
        'strokeWidth': '2',
        'strokeLinecap': 'round',
        'strokeLinejoin': 'round'
    }), _createElement('path', {
        'id': 'Vector_2',
        'd': 'M3 3L13 13',
        'stroke': 'black',
        'strokeWidth': '2',
        'strokeLinecap': 'round',
        'strokeLinejoin': 'round'
    }))))), [_createElement('div', {
            'className': 'cm_req-panel_pump_inner',
            'key': '28930'
        }, _createElement.apply(this, [
            'div',
            { 'className': 'cm_req-panel_pump_select-container  cmRepeater_selects' },
            _map(this.selects, repeatSelects1.bind(this))
        ]), _createElement.apply(this, [
            'div',
            { 'className': 'cm_req-panel_pump_input cmRepeater_inputs' },
            _map(this.inputs, repeatInputs2.bind(this))
        ]), _createElement('div', { 'className': 'cm_req-panel_pump_button-container' }, _createElement('button', {
            'onClick': this.clear,
            'className': 'cm_req-panel_button__clear',
            'disabled': this.disableClear
        }, [_createElement('svg', {
                'width': '24',
                'height': '24',
                'viewBox': '0 0 24 24',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '93910'
            }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
                'id': 'mask0_1239_10799',
                'style': { maskType: 'alpha' },
                'maskUnits': 'userSpaceOnUse',
                'x': '0',
                'y': '0',
                'width': '24',
                'height': '24'
            }, _createElement('rect', {
                'id': 'Bounding box',
                'width': '24',
                'height': '24',
                'fill': '#D9D9D9'
            })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
                'id': 'restart_alt_2',
                'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
                'fill': 'white'
            }))))], '\n      Reset\n    '), _createElement('button', {
            'onClick': this.submit,
            'className': 'button button--primary  button cm_req-panel_button__go',
            'disabled': !this.hasRequiredSelection
        }, '\n      find my pump\n      ', _createElement('i', {
            'aria-hidden': 'true',
            'className': 'fa fa-angle-right'
        }))))]));
}
        export const componentNames = ["cm:filterInput","cm:facetInput"]