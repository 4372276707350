
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function productDataRT () {
    function repeatFieldName1(attributes, tables, table, tableIndex, fieldName, fieldNameIndex) {
        return _createElement('th', { 'key': table.name + '_' + fieldName }, fieldName);
    }
    function repeatVal2(attributes, tables, table, tableIndex, line, lineIndex, val, valIndex) {
        return _createElement('td', { 'key': table.name + '_' + line.join('') + '_' + val }, val);
    }
    function repeatLine3(attributes, tables, table, tableIndex, line, lineIndex) {
        return _createElement.apply(this, [
            'tr',
            { 'key': line.join('') },
            _map(line, repeatVal2.bind(this, attributes, tables, table, tableIndex, line, lineIndex))
        ]);
    }
    function repeatTable4(attributes, tables, table, tableIndex) {
        return _createElement('div', {
            'className': 'cm_product-details',
            'key': 'cm_product-details_' + table.name
        }, _createElement('div', { 'className': 'cm_product-details__header' }, _createElement('span', { 'className': 'cm_product-details__header-label' }, 'Mounting: '), _createElement('span', { 'className': 'cm_product-details__header-value' }, table.name)), _createElement('table', { 'className': 'cm_product-details__content' }, _createElement('thead', {}, _createElement.apply(this, [
            'tr',
            {},
            _map(table.value[0], repeatFieldName1.bind(this, attributes, tables, table, tableIndex))
        ])), _createElement.apply(this, [
            'tbody',
            {},
            _map(table.value.slice(1), repeatLine3.bind(this, attributes, tables, table, tableIndex))
        ])));
    }
    function scopeAttributesTables5() {
        var attributes = JSON.parse(this.productData.find(prop => prop.field === 'PumpSearchProductAttributes')?.value);
        var tables = Object.entries(attributes).map(t => ({
            name: t[0],
            value: t[1]
        }));
        return _createElement.apply(this, [
            'div',
            { 'className': 'cm_product-details-container' },
            _map(tables, repeatTable4.bind(this, attributes, tables))
        ]);
    }
    return this.productData.find(prop => prop.field === 'PumpSearchProductAttributes') ? _createElement('details', { 'key': '0' }, _createElement('summary', { 'role': 'button' }, _createElement('div', { 'className': 'summary__title' }, _createElement('h2', { 'className': 'accordion__title text-body' }, 'Pump Applications')), [_createElement('svg', {
            'width': '12',
            'height': '12',
            'viewBox': '0 0 12 12',
            'aria-hidden': 'true',
            'focusable': 'false',
            'role': 'presentation',
            'className': 'icon icon-plus',
            'fill': 'none',
            'key': '2510'
        }, _createElement('path', {
            'd': 'M6 0V12',
            'stroke': 'currentColor',
            'strokeWidth': '2'
        }), _createElement('path', {
            'd': 'M0 6L12 6',
            'stroke': 'currentColor',
            'strokeWidth': '2'
        }))], [_createElement('svg', {
            'width': '12',
            'height': '12',
            'viewBox': '0 0 12 12',
            'aria-hidden': 'true',
            'focusable': 'false',
            'role': 'presentation',
            'className': 'icon icon-minus',
            'fill': 'none',
            'key': '5510'
        }, _createElement('path', {
            'd': 'M0 6L12 6',
            'stroke': 'currentColor',
            'strokeWidth': '2'
        }))]), _createElement('div', { 'className': 'accordion__content' }, scopeAttributesTables5.apply(this, []))) : null;
}
        export const componentNames = []